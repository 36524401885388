import React from "react";
import { Link } from "react-router-dom";
import browserRoute from "./../../Routes/browserRoutes";
import withMainLayout from "./../HOC/withMainLayout";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { transactionList } from "../../Redux/actions/actions";
import { getHoursMinutes } from "../Dashboard/Transactions";
import { tokenSymbol } from "../TokenInfo";
import MaterialTable from "material-table";
const Transactions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(transactionList());
  }, []);

  const getTranx = useSelector((state) => state?.auth?.transactionLists);
  console.log("getTans", getTranx);

  // const tranx_num = getTranx.map((data)=>data.tranx_num)
  const columns = [
    {
      title: "TRANX NO",
      field: "tranxNo",
      render: (rowData) => (
        <div class="data-col dt-tnxno">
          <div class="d-flex align-items-center">
            <div class="data-state b-data-state-approved-transaction">
              <span class="d-none">Pending</span>
            </div>
            <div class="fake-class">
              <span class="lead tnx-id">{rowData.tranx}</span>
              <span class="sub sub-date">{rowData.updated_at}</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "TOKENS",
      field: "tokens",
      render: (rowData) => (
        <div class="data-col dt-token">
          <span class="lead token-amount">{rowData.tokens}</span>
          <span class="sub sub-symbol">{tokenSymbol}</span>
        </div>
      ),
    },
    {
      title: "AMOUNT",
      field: "amount",
      render: (rowData) => (
        <div class="data-col dt-amount">
          <span class="lead amount-pay">{rowData.amount}</span>
          <span class="sub sub-symbol">
            ETH{" "}
            <em
              class="fas fa-info-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title={`1 ETH = 1250 ${tokenSymbol}`}
            ></em>
          </span>
        </div>
      ),
    },
    {
      title: "USD AMOUNT",
      field: "usdAmount",
      render: (rowData) => (
        <div class="data-col dt-usd-amount">
          <span class="lead amount-pay">{rowData.usdAmount}</span>
          <span class="sub sub-symbol">
            USD{" "}
            <em
              class="fas fa-info-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title="1 ETH = 350.54 USD"
            ></em>
          </span>
        </div>
      ),
    },
    {
      title: "FROM",
      field: "from",
      render: (rowData) => (
        <div class="data-col dt-account">
          <span class="lead user-info">{rowData.wallatFrom}</span>
          <span class="sub sub-date">{rowData.transactionTime}</span>
        </div>
      ),
    },
    {
      title: "TYPE",
      field: "type",
      render: (rowData) => (
        <div class="data-col dt-type">
          <span class="dt-type-md badge badge-outline badge-success badge-md">
            {rowData.transactionType}
          </span>
        </div>
      ),
    },
  ];
  const data1 = getTranx?.map((list) => {
    return {
      tranx: list.transaction_id.substring(4, list.transaction_id.length),
      updated_at:
        new Date(list.updated_at).toString("dddd MMM yyyy").substring(0, 15) +
        " " +
        getHoursMinutes(list.updated_at),
      tokens:
        list.tokens.toString().length > 12
          ? list.tokens
              .toPrecision(40)
              .substring(
                0,
                list.tokens.toPrecision(35).split(".")[0].length - 12
              ) + " Trillion"
          : list.tokens.toPrecision(35).split(".")[0].length > 9
          ? list.tokens
              .toPrecision(40)
              .substring(
                0,
                list.tokens.toPrecision(35).split(".")[0].length - 9
              ) + " Billion"
          : list.tokens.toPrecision(35).split(".")[0].length > 6
          ? list.tokens
              .toString()
              .substring(
                0,
                list.tokens.toPrecision(35).split(".")[0].length - 6
              ) + " Million"
          : list.tokens,
      amount: list.eth,
      usdAmount: list.value_usd.toFixed(2),
      wallatFrom: list.wallet_from
        ? list.wallet_from.substring(0, 5) +
          "...." +
          list.wallet_from.substring(37, 42)
        : "Not Available",
      transactionTime:
        new Date(list.transaction_time)
          .toString("dddd MMM yyyy")
          .substring(0, 15) +
        " " +
        getHoursMinutes(list.transaction_time),
      transactionType: list.transaction_type,
    };
  });
  return (
    <div class="page-content mt-3">
      <div class="container-fluid">
        <MaterialTable
          title="Transactions"
          columns={columns}
          data={data1}
          style={{
            borderRadius: "15px",
            padding: 30,
            paddingTop: 20,
          }}
        />
      </div>
    </div>

    // ========================old design ==========================
    // <div className='page-content'>
    //   <div className='container'>
    //     <div className='card content-area'>
    //       <div className='card-innr'>
    //         <div className='card-head'>
    //           <h4 className='card-title'>User Transactions</h4>
    //         </div>
    //         <table className='data-table dt-init user-tnx'>
    //           <thead>
    //             <tr className='data-item data-head'>
    //               <th className='data-col dt-tnxno'>Tranx NO</th>
    //               <th className='data-col dt-token'>Tokens</th>
    //               <th className='data-col dt-amount'>Amount</th>
    //               <th className='data-col dt-usd-amount'>USD Amount</th>
    //               <th className='data-col dt-account'>From</th>
    //               <th className='data-col dt-type'>
    //                 <div className='dt-type-text'>Type</div>
    //               </th>
    //               <th className='data-col'></th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {getTranx.length !== 0 &&
    //               getTranx.map((list) => (
    //                 <tr className='data-item'>
    //                   <td className='data-col dt-tnxno'>
    //                     <div className='d-flex align-items-center'>
    //                       <div className='data-state data-state-approved'>
    //                         <span className='d-none'>Pending</span>
    //                       </div>
    //                       <div className='fake-className'>
    //                         <span className='lead tnx-id'>
    //                           {'TX '}
    //                           {list.transaction_id.substring(
    //                             4,
    //                             list.transaction_id.length
    //                           )}
    //                         </span>
    //                         <span className='sub sub-date'>
    //                           {new Date(list.updated_at)
    //                             .toString('dddd MMM yyyy')
    //                             .substring(0, 15)}
    //                           {' ' + getHoursMinutes(list.updated_at)}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td className='data-col dt-token'>
    //                     <span className='lead token-amount'>
    //                       {' '}
    //                       {list.tokens.toString().length > 12
    //                         ? list.tokens
    //                             .toPrecision(40)
    //                             .substring(
    //                               0,
    //                               list.tokens.toPrecision(35).split('.')[0]
    //                                 .length - 12
    //                             ) + ' Trillion'
    //                         : list.tokens.toPrecision(35).split('.')[0].length >
    //                           9
    //                         ? list.tokens
    //                             .toPrecision(40)
    //                             .substring(
    //                               0,
    //                               list.tokens.toPrecision(35).split('.')[0]
    //                                 .length - 9
    //                             ) + ' Billion'
    //                         : list.tokens.toPrecision(35).split('.')[0].length >
    //                           6
    //                         ? list.tokens
    //                             .toString()
    //                             .substring(
    //                               0,
    //                               list.tokens.toPrecision(35).split('.')[0]
    //                                 .length - 6
    //                             ) + ' Million'
    //                         : list.tokens}
    //                     </span>
    //                     <span className='sub sub-symbol'>{tokenSymbol}</span>
    //                   </td>
    //                   <td className='data-col dt-amount'>
    //                     <span className='lead amount-pay'>{list.eth}</span>
    //                     <span className='sub sub-symbol'>
    //                       ETH
    //                       <em
    //                         className='fas fa-info-circle'
    //                         data-toggle='tooltip'
    //                         data-placement='bottom'
    //                         title={`1 ETH = 1250 ${tokenSymbol}`}
    //                       ></em>
    //                     </span>
    //                   </td>
    //                   <td className='data-col dt-usd-amount'>
    //                     <span className='lead amount-pay'>
    //                       {list.value_usd}
    //                     </span>
    //                     <span className='sub sub-symbol'>
    //                       USD
    //                       <em
    //                         className='fas fa-info-circle'
    //                         data-toggle='tooltip'
    //                         data-placement='bottom'
    //                         title='1 ETH = 350.54 USD'
    //                       ></em>
    //                     </span>
    //                   </td>
    //                   <td className='data-col dt-account'>
    //                     <span className='lead user-info'>
    //                       {list.wallet_from
    //                         ? list.wallet_from.substring(0, 5) +
    //                           '....' +
    //                           list.wallet_from.substring(37, 42)
    //                         : 'Not Available'}
    //                     </span>
    //                     <span className='sub sub-date'>
    //                       {new Date(list.transaction_time)
    //                         .toString('dddd MMM yyyy')
    //                         .substring(0, 15)}
    //                       {' ' + getHoursMinutes(list.transaction_time)}
    //                     </span>
    //                   </td>
    //                   <td className='data-col dt-type'>
    //                     <span
    //                       className='
    //                   dt-type-md
    //                   badge badge-outline badge-success badge-md tran-btn
    //                 '
    //                     >
    //                       {list.transaction_type}
    //                     </span>
    //                     {/* <span
    //                   className="
    //                   dt-type-sm
    //                   badge badge-sq badge-outline badge-success badge-md
    //                 "
    //                 >
    //                   P
    //                 </span> */}
    //                   </td>{' '}
    //                   <td className='data-col'>
    //                     <a
    //                       data-toggle='tooltip'
    //                       data-placement='top'
    //                       title='view on EtherScan'
    //                       href={`https://ropsten.etherscan.io/tx/${list.transaction_hash}`}
    //                       target='_blank'
    //                       // data-toggle="modal"
    //                       // data-target="#transaction-details"
    //                       className='btn btn-sm btn-icon'
    //                     >
    //                       <em
    //                         style={{ color: 'grey' }}
    //                         className='fab fa-ethereum'
    //                       ></em>
    //                     </a>
    //                   </td>
    //                 </tr>
    //               ))}
    //             {/* <tr className="data-item">
    //               <td className="data-col dt-tnxno">
    //                 <div className="d-flex align-items-center">
    //                   <div className="data-state data-state-approved">
    //                     <span className="d-none">Approved</span>
    //                   </div>
    //                   <div className="fake-className">
    //                     <span className="lead tnx-id">TNX1002</span>
    //                     <span className="sub sub-date">2018-08-24 10:45PM</span>
    //                   </div>
    //                 </div>
    //               </td>
    //               <td className="data-col dt-token">
    //                 <span className="lead token-amount">18,750</span>
    //                 <span className="sub sub-symbol">{tokenSymbol}</span>
    //               </td>
    //               <td className="data-col dt-amount">
    //                 <span className="lead amount-pay">50.00</span>
    //                 <span className="sub sub-symbol">
    //                   ETH
    //                   <em
    //                     className="fas fa-info-circle"
    //                     data-toggle="tooltip"
    //                     data-placement="bottom"
    //                     title={`1 ETH = 1250 ${tokenSymbol}`}
    //                   ></em>
    //                 </span>
    //               </td>
    //               <td className="data-col dt-usd-amount">
    //                 <span className="lead amount-pay">245.52</span>
    //                 <span className="sub sub-symbol">
    //                   USD
    //                   <em
    //                     className="fas fa-info-circle"
    //                     data-toggle="tooltip"
    //                     data-placement="bottom"
    //                     title="1 ETH = 350.54 USD"
    //                   ></em>
    //                 </span>
    //               </td>
    //               <td className="data-col dt-account">
    //                 <span className="lead user-info">1F1T....4XQX</span>
    //                 <span className="sub sub-date">08 Jul, 18 10:20PM</span>
    //               </td>
    //               <td className="data-col dt-type">
    //                 <span
    //                   className="
    //                   dt-type-md
    //                   badge badge-outline badge-success badge-md tran-btn
    //                 "
    //                 >
    //                   Purchase
    //                 </span>
    //                 {/* <span
    //                   className="
    //                   dt-type-sm
    //                   badge badge-sq badge-outline badge-success badge-md
    //                 "
    //                 >
    //                   P
    //                 </span> */}
    //             {/* </td>
    //               <td className="data-col text-right">
    //                 <Link
    //                   to={browserRoute.TRANSACTION_DETAILS}
    //                   // data-toggle="modal"
    //                   // data-target="#transaction-details"
    //                   className="btn btn-light-alt btn-xs btn-icon"
    //                 >
    //                   <em className="ti ti-eye"></em>
    //                 </Link>
    //               </td>
    //             </tr>  */}
    //           </tbody>
    //         </table>
    //       </div>
    //       {/* <!-- .card-innr --> */}
    //     </div>
    //     {/* <!-- .card --> */}
    //   </div>
    //   {/* <!-- .container --> */}
    // </div>
  );
};

export default withMainLayout(Transactions);
{
  /* <td className="data-col text-right"> */
}
{
  /* <div className="relative d-inline-block d-md-none">
<a
href="#"
className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
>
<em className="ti ti-more-alt"></em>
</a>
<div
className="
toggle-className
dropdown-content dropdown-content-center-left
pd-2x
"
>
<ul className="data-action-list">
  <li>
    <a
      href="#"
      className="btn btn-auto btn-primary pay-btn"
    >
      <span>
        Pay
        <span className="d-none d-xl-inline-block">
          Now
        </span>
      </span>
      <em className="ti ti-wallet"></em>
    </a>
  </li>
  <li>
    <a
      href="#"
      className="btn btn-danger-alt btn-xs btn-icon"
    >
      <em className="ti ti-trash"></em>
    </a>
  </li>
</ul>
</div>
</div> */
}
{
  /* <ul className="data-action-list d-none d-md-inline-flex">
  <li>
    <a
      href="#"
      className="btn btn-auto btn-primary btn-xs"
    >
      <span>
        Pay
        <span className="d-none d-xl-inline-block">
          Now
        </span>
      </span>
      <em className="ti ti-wallet"></em>
    </a>
  </li>
  <li>
    <a
      href="#"
      className="btn btn-danger-alt btn-xs btn-icon"
    >
      <em className="ti ti-trash"></em>
    </a>
  </li>
</ul> */
}
// </td>
