import { Modal } from "react-bootstrap";
import { WalletConnect } from "../../../Redux/actions/actions";
import { useDispatch } from "react-redux";
const WalletConnectModal = ({ selectionModal, setSelectionModal }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={selectionModal}
      onHide={() => {
        setSelectionModal(false);
      }}
      // backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        {/* <!-- Main Modal  --> */}
        <div
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
        >
          <div>
            <div className="">
              <div className="modal-header" style={{ border: "none" }}>
                <h3
                  className="modal-title n-modalHeader"
                  id="exampleModalLabel"
                  style={{}}
                >
                  Connect wallet
                </h3>
                {/* <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                setMainModel(false);
              }}
            >
              <span className="popupcrosss" aria-hidden="true">
                &times;
              </span>
            </button> */}
                {/* <img
              className="popupcrosss"
              onClick={() => {
                setMainModel(false);
              }}
              src={Images.crossicon}
            /> */}
                <svg
                  style={{ cursor: "pointer" }}
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="n-modalCloseIcon"
                  onClick={() => {
                    setSelectionModal(false);
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L20 18.5858L25.2929 13.2929C25.6834 12.9024 26.3166 12.9024 26.7071 13.2929C27.0976 13.6834 27.0976 14.3166 26.7071 14.7071L21.4142 20L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L20 21.4142L14.7071 26.7071C14.3166 27.0976 13.6834 27.0976 13.2929 26.7071C12.9024 26.3166 12.9024 25.6834 13.2929 25.2929L18.5858 20L13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929Z"
                    fill="#23262F"
                  />
                  <rect
                    x="1"
                    y="1"
                    width="38"
                    height="38"
                    rx="19"
                    stroke="#E6E8EC"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-center modalparagraph mb-3">
                  <p
                    style={{
                      color: "#777E90",
                      fontSize: "14px",
                      padding: "15px",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "5px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    To use our platform you will need to connect a wallet.
                    Please choose one of the listed.
                  </p>
                </div>
                <h5
                  style={{
                    fontSize: "12px",
                    color: "#B1B5C3",
                    fontWeight: "400",

                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  CHOOSE WALLET
                </h5>
                <button
                  className="d-flex justify-content-between connectwallet mt-3 mb-3 p-2"
                  style={{
                    width: "100%",
                    background: "none",
                    border: "1px solid #D3D3D3",
                  }}
                  //   onClick={connectXdefi}
                >
                  <div className=" d-flex">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 -3 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2.39999"
                        y="2.40002"
                        width="19.2"
                        height="19.2"
                        rx="9.6"
                        fill="url(#paint0_linear_4905_106381)"
                      />
                      <path
                        d="M13.2352 12.7154C12.174 13.3823 10.7541 13.7259 9.31562 13.6524C8.10599 13.5925 7.11429 13.1522 6.51467 12.4287C5.98753 11.7816 5.7832 10.9279 5.92148 9.94559C5.96848 9.61906 6.06446 9.30218 6.2067 9.00509L6.22649 8.96354C7.60111 6.28405 10.6628 4.97027 13.5214 5.83235C16.3807 6.69492 18.2322 9.49018 17.9342 12.494C17.6361 15.4983 15.2722 17.8651 12.3009 18.1347L12.3671 18.9001C15.7032 18.5993 18.3579 15.9423 18.6921 12.5692C19.0263 9.19631 16.9457 6.05845 13.7348 5.09346C10.524 4.12823 7.08881 5.60749 5.54944 8.61846L5.52297 8.67337C5.34671 9.04226 5.22784 9.43591 5.17047 9.8407C5.00572 11.038 5.26101 12.1034 5.92841 12.9239C6.65814 13.8209 7.84625 14.3544 9.27134 14.4244C11.0061 14.513 12.7313 14.0343 13.9553 13.154L13.2354 12.7154H13.2352Z"
                        fill="white"
                      />
                      <path
                        d="M14.8219 13.7053C14.1298 14.2723 12.5209 15.3004 9.85179 15.4406C6.86358 15.5965 5.61834 14.6804 5.60696 14.6713L5.36553 14.9516L5.60844 14.6757L5.12112 15.2274C5.17406 15.2694 6.36811 16.179 9.17302 16.179C9.40233 16.179 9.64351 16.179 9.89459 16.1602C13.1205 15.9905 14.8929 14.6634 15.5106 14.0964L14.8219 13.7053Z"
                        fill="white"
                      />
                      <path
                        d="M16.082 14.4539C15.6665 14.9844 15.1582 15.4351 14.5817 15.784C12.5409 17.0654 9.94505 17.2311 8.12788 17.1418L8.08954 17.8896C8.38386 17.9037 8.67848 17.9107 8.97314 17.9107C14.0981 17.9107 16.1691 15.6398 16.7474 14.8287L16.0805 14.4475"
                        fill="white"
                      />
                      <path
                        d="M15.8816 11.479C16.2232 11.479 16.5001 11.2022 16.5001 10.8606C16.5001 10.5191 16.2232 10.2422 15.8816 10.2422C15.5401 10.2422 15.2632 10.5191 15.2632 10.8606C15.2632 11.2022 15.5401 11.479 15.8816 11.479Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4905_106381"
                          x1="5.69999"
                          y1="4.65002"
                          x2="17.55"
                          y2="23.1"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#1F40E0" />
                          <stop offset="1" stop-color="#0F1B59" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <a
                      className="n-walletType"
                      style={{
                        // color: "#23262F",
                        fontSize: "14px",

                        paddingLeft: "10px",
                        fontWeight: "600",
                        padding: "4px",
                      }}
                    >
                      XDEFI WALLET
                    </a>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 -4 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "10px", marginTop: "2px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.31952 5.32544C6.89349 5.75935 6.89349 6.46287 7.31952 6.89679L10.3663 10L7.31952 13.1032C6.89349 13.5372 6.89349 14.2407 7.31952 14.6745C7.74555 15.1085 8.43627 15.1085 8.86228 14.6745L12.6805 10.7857C13.1065 10.3518 13.1065 9.64825 12.6805 9.21434L8.86228 5.32544C8.43627 4.89152 7.74555 4.89152 7.31952 5.32544Z"
                        fill="#777E91"
                      />
                    </svg>
                  </div>
                </button>
                <button
                  className="d-flex justify-content-between connectwallet btnHoverWhite p-2"
                  style={{
                    width: "100%",
                    background: "none",
                    border: "1px solid #D3D3D3",
                  }}
                  //   onClick={connectWalletConnect}
                >
                  <div className="d-flex flex-row align-items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33293 8.69904C9.46307 5.63368 14.5369 5.63368 17.6711 8.69904L18.0477 9.06753C18.2056 9.22141 18.2056 9.46842 18.0477 9.62229L16.76 10.8857C16.6831 10.9626 16.5535 10.9626 16.4766 10.8857L15.9582 10.3795C13.7756 8.24147 10.2325 8.24147 8.04985 10.3795L7.49509 10.9221C7.41815 10.9991 7.28857 10.9991 7.21164 10.9221L5.91989 9.65874C5.76197 9.50486 5.76197 9.25785 5.91989 9.10398L6.33293 8.69904ZM20.3356 11.3068L21.4815 12.4285C21.6395 12.5824 21.6395 12.8294 21.4815 12.9833L16.3105 18.049C16.1526 18.2029 15.9015 18.2029 15.7436 18.049L12.0709 14.4532C12.0304 14.4167 11.9696 14.4167 11.9291 14.4532L8.26042 18.049C8.10249 18.2029 7.85143 18.2029 7.69351 18.049L2.51844 12.9873C2.36051 12.8334 2.36051 12.5864 2.51844 12.4325L3.6644 11.3109C3.82233 11.157 4.07339 11.157 4.23131 11.3109L7.90407 14.9067C7.94457 14.9431 8.00531 14.9431 8.0458 14.9067L11.7145 11.3109C11.8724 11.157 12.1235 11.157 12.2814 11.3109L15.9542 14.9067C15.9947 14.9431 16.0554 14.9431 16.0959 14.9067L19.7687 11.3109C19.9225 11.1529 20.1777 11.1529 20.3356 11.3068Z"
                        fill="#3A99FB"
                      />
                    </svg>
                    <a
                      className="n-walletType"
                      style={{
                        // color: "#23262F",
                        fontSize: "14px",

                        paddingLeft: "10px",
                        fontWeight: "600",
                        padding: "4px",
                      }}
                    >
                      WALLETCONNECT
                    </a>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 -4 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "10px", marginTop: "2px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.31952 5.32544C6.89349 5.75935 6.89349 6.46287 7.31952 6.89679L10.3663 10L7.31952 13.1032C6.89349 13.5372 6.89349 14.2407 7.31952 14.6745C7.74555 15.1085 8.43627 15.1085 8.86228 14.6745L12.6805 10.7857C13.1065 10.3518 13.1065 9.64825 12.6805 9.21434L8.86228 5.32544C8.43627 4.89152 7.74555 4.89152 7.31952 5.32544Z"
                        fill="#777E91"
                      />
                    </svg>
                  </div>
                </button>
                <button
                  className="d-flex justify-content-between connectwallet btnHoverWhite mt-3 p-2"
                  style={{
                    width: "100%",
                    background: "none",
                    border: "1px solid #D3D3D3",
                  }}
                  onClick={() => {
                    dispatch(WalletConnect());
                    setSelectionModal(false);
                  }}
                >
                  <div className="d-flex flex-row align-center-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 -3 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.6505 2.40002L13.15 8.40002L14.5447 4.86403L20.6505 2.40002Z"
                        fill="#E17726"
                        stroke="#E17726"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.34958 2.40002L10.7833 8.45602L9.45533 4.86402L3.34958 2.40002Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.9498 16.312L15.9541 19.608L20.2274 20.88L21.4515 16.3841L17.9498 16.312Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.55573 16.3841L3.77241 20.88L8.03828 19.608L6.05001 16.312L2.55573 16.3841Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.80825 10.744L6.62125 12.68L10.85 12.888L10.7091 7.96802L7.80825 10.744Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.1915 10.7441L13.2463 7.91211L13.1498 12.8881L17.3785 12.6801L16.1915 10.7441Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.03833 19.608L10.5978 18.272L8.39445 16.416L8.03833 19.608Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.4022 18.272L15.9542 19.608L15.6055 16.416L13.4022 18.272Z"
                        fill="#E27625"
                        stroke="#E27625"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9542 19.6081L13.4022 18.2721L13.6099 20.0641L13.5876 20.8241L15.9542 19.6081Z"
                        fill="#D5BFB2"
                        stroke="#D5BFB2"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.03833 19.6081L10.4124 20.8241L10.3975 20.0641L10.5978 18.2721L8.03833 19.6081Z"
                        fill="#D5BFB2"
                        stroke="#D5BFB2"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.4568 15.232L8.33502 14.5601L9.83363 13.816L10.4568 15.232Z"
                        fill="#233447"
                        stroke="#233447"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5431 15.232L14.1663 13.816L15.6724 14.5601L13.5431 15.232Z"
                        fill="#233447"
                        stroke="#233447"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.03829 19.608L8.40925 16.312L6.05005 16.3841L8.03829 19.608Z"
                        fill="#CC6228"
                        stroke="#CC6228"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.5906 16.312L15.9542 19.608L17.9498 16.3841L15.5906 16.312Z"
                        fill="#CC6228"
                        stroke="#CC6228"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.3785 12.6801L13.1498 12.8881L13.543 15.2321L14.1662 13.8161L15.6722 14.5601L17.3785 12.6801Z"
                        fill="#CC6228"
                        stroke="#CC6228"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.335 14.5601L9.8336 13.8161L10.4568 15.2321L10.85 12.8881L6.62125 12.6801L8.335 14.5601Z"
                        fill="#CC6228"
                        stroke="#CC6228"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.6214 12.6801L8.39452 16.4161L8.33513 14.5601L6.6214 12.6801Z"
                        fill="#E27525"
                        stroke="#E27525"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.6724 14.5601L15.6056 16.4161L17.3787 12.6801L15.6724 14.5601Z"
                        fill="#E27525"
                        stroke="#E27525"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.8502 12.8881L10.457 15.2321L10.9541 18.0001L11.0654 14.3521L10.8502 12.8881Z"
                        fill="#E27525"
                        stroke="#E27525"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.1499 12.8881L12.9422 14.3441L13.046 18.0001L13.5431 15.2321L13.1499 12.8881Z"
                        fill="#E27525"
                        stroke="#E27525"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5431 15.2321L13.0461 18L13.4022 18.2721L15.6056 16.4161L15.6723 14.5601L13.5431 15.2321Z"
                        fill="#F5841F"
                        stroke="#F5841F"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.33502 14.5601L8.39441 16.4161L10.5978 18.2721L10.9539 18L10.4568 15.2321L8.33502 14.5601Z"
                        fill="#F5841F"
                        stroke="#F5841F"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5877 20.824L13.6099 20.064L13.417 19.888H10.583L10.3975 20.064L10.4124 20.824L8.03833 19.608L8.86924 20.344L10.5533 21.6H13.4392L15.1307 20.344L15.9542 19.608L13.5877 20.824Z"
                        fill="#C0AC9D"
                        stroke="#C0AC9D"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.4021 18.272L13.046 18H10.9539L10.5978 18.272L10.3975 20.064L10.583 19.888H13.417L13.6099 20.064L13.4021 18.272Z"
                        fill="#161616"
                        stroke="#161616"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.9694 8.792L21.6 5.48002L20.6504 2.40002L13.4022 8.20005L16.1916 10.744L20.131 11.984L20.9991 10.888L20.6207 10.592L21.2216 10.0001L20.7617 9.61604L21.3626 9.12002L20.9694 8.792Z"
                        fill="#763E1A"
                        stroke="#763E1A"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.39999 5.48002L3.03802 8.792L2.62998 9.12002L3.23833 9.61604L2.77836 10.0001L3.37928 10.592L3.00092 10.888L3.86893 11.984L7.80837 10.744L10.5978 8.20005L3.34961 2.40002L2.39999 5.48002Z"
                        fill="#763E1A"
                        stroke="#763E1A"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.1311 11.984L16.1917 10.744L17.3787 12.68L15.6056 16.416L17.95 16.384H21.4517L20.1311 11.984Z"
                        fill="#F5841F"
                        stroke="#F5841F"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.80825 10.744L3.86886 11.984L2.55573 16.384H6.05001L8.39434 16.416L6.62126 12.68L7.80825 10.744Z"
                        fill="#F5841F"
                        stroke="#F5841F"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.1499 12.888L13.4021 8.19998L14.5446 4.86401H9.45529L10.5978 8.19998L10.85 12.888L10.9465 14.36L10.9539 18H13.046L13.0534 14.36L13.1499 12.888Z"
                        fill="#F5841F"
                        stroke="#F5841F"
                        stroke-width="0.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <a
                      className="n-walletType"
                      style={{
                        // color: "#23262F",
                        fontSize: "14px",

                        paddingLeft: "10px",
                        fontWeight: "600",
                        padding: "4px",
                      }}
                    >
                      META MASK{" "}
                      {/* {xdfiExtension
                        ? "DISABLE XDEFI WALLET"
                        : "METAMASK WALLET"} */}
                    </a>
                  </div>
                  <div className="d-flex flex-row align-center-center">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 -4 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "10px", marginTop: "2px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.31952 5.32544C6.89349 5.75935 6.89349 6.46287 7.31952 6.89679L10.3663 10L7.31952 13.1032C6.89349 13.5372 6.89349 14.2407 7.31952 14.6745C7.74555 15.1085 8.43627 15.1085 8.86228 14.6745L12.6805 10.7857C13.1065 10.3518 13.1065 9.64825 12.6805 9.21434L8.86228 5.32544C8.43627 4.89152 7.74555 4.89152 7.31952 5.32544Z"
                        fill="#777E91"
                      />
                    </svg>
                  </div>
                </button>

                <button
                  className="d-flex justify-content-between connectwallet btnHoverWhite mt-3 mb-3 p-2"
                  style={{
                    width: "100%",
                    background: "none",
                    border: "1px solid #D3D3D3",
                  }}
                  onClick={() => {
                    // setMainModel(false);
                    // setSelectionModal(true);
                  }}
                >
                  {" "}
                  <a
                    className="n-walletType"
                    style={{
                      // color: "#23262F",
                      fontSize: "14px",

                      fontWeight: "600",
                      lineHeight: "24px",
                      padding: "4px",
                    }}
                  >
                    CUSTOM KEYSTORE
                  </a>
                  <div className="d-flex flex-row align-center-center">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 -4 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "10px", marginTop: "2px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.31952 5.32544C6.89349 5.75935 6.89349 6.46287 7.31952 6.89679L10.3663 10L7.31952 13.1032C6.89349 13.5372 6.89349 14.2407 7.31952 14.6745C7.74555 15.1085 8.43627 15.1085 8.86228 14.6745L12.6805 10.7857C13.1065 10.3518 13.1065 9.64825 12.6805 9.21434L8.86228 5.32544C8.43627 4.89152 7.74555 4.89152 7.31952 5.32544Z"
                        fill="#777E91"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- modal end --> */}
      </Modal.Body>
    </Modal>
  );
};

export default WalletConnectModal;
