const browserRoute = {
  SIGNIN: '/auth/signin',
  SIGNUP: '/api/v1/auth/signup',
  VERIFY_EMAIL: '/verifyemail/:id',
  FORGOT_PASSWORD: '/auth/forgotpassword',
  RESET_PASSWORD: '/auth/reset-password/:id',
  SIGNUP_SUCCESS: '/auth/signupsuccess',
  DASHBOARD: '/',
  TERMS: '/terms',
  SIDEBAR: '/sidebar',

  BUY_TOKEN: '/buytoken',
  PROFILE: '/profile',
  ICO_DISTRIBUTION: '/icodistribution',
  BOUNTY_PROGRAM: '/bountyprogram',
  AIRDROP: '/airdrop',

  TRANSACTIONS: '/transactions',
  TRANSACTION_DETAILS: '/transactions/details',

  KYC_APPLICATION: '/kyc-application',
  KYC_Tier1: '/kyc-tier1',
  KYC_Tier2: '/kyc-tier2',
  KYC_Tier3: '/kyc-tier3',
  KYC_FORM: '/kycform',
  KYC_THANKYOU: '/kyc-thankyou',
};

export default browserRoute;
